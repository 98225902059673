import React, { useContext } from "react";

import type { Topic } from "../../../client/";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { LinkToTopic } from "../../Link";
import { TrackingContext } from "../../../contexts/TrackingContext";
import { useSubcribedTopics } from "../../../hooks/useSubcribedTopics";

export function ArticleTopics() {
  const {
    article: { story, tags }
  } = useContext(ArticleContext);

  return (
    <div className="article-topics clearfix">
      {story && <ArticleTopic topic={story} />}
      {tags
        .filter(tag => tag.type !== "machine")
        .map(topic => (
          <ArticleTopic key={topic.topic_id} topic={topic} />
        ))}
    </div>
  );
}

function ArticleTopic({ topic }: { topic: Topic }) {
  const { articleTopic, fav, unfav } = useContext(TrackingContext);
  const { article } = useContext(ArticleContext);
  const { isSubscribedToTopic, addSubscription, removeSubscription } =
    useSubcribedTopics();
  const subscribed = isSubscribedToTopic(topic);

  const onClick = () => {
    if (subscribed) {
      removeSubscription(topic);
      unfav(topic.topic_id, article);
    } else {
      addSubscription(topic);
      fav(topic.topic_id, article);
    }
  };

  return (
    <div data-isclickable="true" className="article-topics-topic">
      <LinkToTopic
        topic={topic}
        className="article-topics-topic--title"
        onClick={() => {
          articleTopic(topic.topic_id, article);
        }}
      >
        {topic.title}
      </LinkToTopic>
      <div
        data-isclickable="true"
        role="presentation"
        onClick={onClick}
        onKeyPress={() => {}}
        className={`article-topics-topic-starbtn ${
          subscribed ? "article-topics-topic-starbtn--active" : ""
        }`}
        aria-label={`Bevaka ${topic.title}`}
      >
        <i
          data-isclickable="true"
          className={`article-topics-topic-star icon icon--star${
            subscribed ? "--active" : ""
          }`}
        />
      </div>
    </div>
  );
}
