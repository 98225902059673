import React from "react";

import { ShareArticle } from "./ShareArticle";
import { ArticleTopics } from "./ArticleTopics";
import { ArticleAd } from "./ArticleAd";
import { ArticleMeta } from "./ArticleMeta";

export function ArticleSide({ onClose }: { onClose?: () => void }) {
  return (
    <div className="main-narrow">
      <div className="article-topiccolumn clearfix">
        {onClose && (
          <button
            className="article-close btn btn--clean hidden-xs hidden-sm"
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
          >
            <i className="icon icon--x" />
          </button>
        )}
        <ArticleMeta />
        <ArticleAd />
        <ShareArticle />
        <div className="box">
          <h2 className="box-heading">Relaterade ämnen</h2>
          <ArticleTopics />
        </div>
      </div>
    </div>
  );
}
