import React, { useContext } from "react";
import Image from "next/image";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { ImageResource } from "../Resources/ImageResource";
import { getImageSizes } from "../../../helpers";

// eslint-disable-next-line import/no-unresolved
import ArrowIcon from "/public/img/svg/chevron-arrow.svg";

const sizes = getImageSizes({ web: "62px", tablet: "10vw", mobile: "20vw" });

export default function FishstickTeaser() {
  const { article } = useContext(ArticleContext);
  const vignette = article.story_vignette;
  const title = article.title.value;
  const image =
    article.main_resource?.type === "Image" && article.main_resource;

  const isSponsored = article.meta?.is_sponsored;

  const getClassName = (className: string) => {
    if (isSponsored) {
      return `${className} ${className}--sponsored`;
    }
    return className;
  };

  return (
    <div className={`resource ${getClassName("resource-fishstick-container")}`}>
      {((image && isSponsored) || article.story_vignette.icon_image) && (
        <div className={getClassName("resource-fishstick-imageContainer")}>
          {image && isSponsored ? (
            <ImageResource resource={image} />
          ) : (
            <Image
              src={article.story_vignette.icon_image?.url.light || ""}
              alt=""
              sizes={sizes}
              width={0}
              height={0}
            />
          )}
        </div>
      )}
      <div className={getClassName("resource-fishstick-content")}>
        <h2 className={getClassName("resource-fishstick-title")}>
          <span className={getClassName("resource-fishstick-vignette-title")}>
            {vignette.title}
          </span>
          <span className="resource-fishstick-vignette-separator">
            {vignette.suffix_separator}
          </span>
          {title}
        </h2>
        {isSponsored && (
          <span className="resource-fishstick-vignette-suffix">
            {vignette.suffix}
          </span>
        )}
      </div>
      {!isSponsored && (
        <div className="resource-fishstick-arrowBox">
          <ArrowIcon className="resource-fishstick-arrowBox-arrow" />
        </div>
      )}
    </div>
  );
}
