import React, { useContext } from "react";

import type { Article, ArticleTeaserLayout } from "../../../client";
import type { TeaserTypes } from "../../../contexts/TeaserContext";

import {
  isThemeBox,
  isDynamicBox,
  getDynamicBoxName
} from "../../../client/helpers";
import { ArticleContainer } from "../../../containers/ArticleContainer";
import { ArticlesFeedContext } from "../../../contexts/ArticlesFeedContext";
import { TeaserContext } from "../../../contexts/TeaserContext";
import { TeaserGroupContext } from "../../../contexts/TeaserGroupContext";
import { TrackingContext } from "../../../contexts/TrackingContext";
import { useEventOnView } from "../../../hooks/useEventOnView";
import { useHidePremiumContent } from "../../../hooks/useHidePremiumContent";
import { getPosition } from "../helpers";
import FishstickTeaser from "./FishstickTeaser";
import FlashTeaser from "./FlashTeaser";
import LargeTeaser from "./LargeTeaser";
import SmallTeaser from "./SmallTeaser";
import SubscriptionTeaser from "./SubscriptionTeaser";
import TinyTeaser from "./TinyTeaser";

export type TeaserComponentType = React.FunctionComponent<{
  article: Article;
}>;

const typeToTeaser: Record<TeaserTypes, TeaserComponentType> = {
  small: SmallTeaser,
  subscription: SubscriptionTeaser,
  flash: FlashTeaser,
  large: LargeTeaser,
  tiny: TinyTeaser,
  fishstick: FishstickTeaser
};

export function getTeaserTypeForArticle({
  teaser_layout: teaserLayout
}: Article) {
  const articleTeaserLayoutMap: {
    [key in ArticleTeaserLayout]: TeaserTypes;
  } = {
    Large: "large",
    SponsoredLarge: "large",
    FeaturedLarge: "large",
    PremiumLarge: "large",
    PassionLarge: "large",

    Small: "small",
    PremiumSmall: "small",
    SponsoredSmall: "small",
    PassionSmall: "small",
    PassionSponsored: "small",

    Flash: "flash",
    Fishstick: "fishstick"
  };

  const validTeaserTypes = Object.keys(
    articleTeaserLayoutMap
  ) as ArticleTeaserLayout[];

  const isValidTeaserLayout =
    teaserLayout && validTeaserTypes.includes(teaserLayout);

  return isValidTeaserLayout ? articleTeaserLayoutMap[teaserLayout] : "large";
}

export function getTeaserComponent({
  article
}: {
  article?: Article;
}): TeaserComponentType {
  if (article) {
    const articleTeaserLayoutMap: {
      [key in ArticleTeaserLayout]: TeaserComponentType;
    } = {
      Large: LargeTeaser,
      SponsoredLarge: LargeTeaser,
      FeaturedLarge: LargeTeaser,
      PremiumLarge: LargeTeaser,
      PassionLarge: LargeTeaser,

      Small: SmallTeaser,
      PremiumSmall: SmallTeaser,
      SponsoredSmall: SmallTeaser,
      PassionSmall: SmallTeaser,
      PassionSponsored: SmallTeaser,

      Flash: FlashTeaser,
      Fishstick: FishstickTeaser
    };

    return articleTeaserLayoutMap[article.teaser_layout || "Large"];
  }
  return LargeTeaser;
}

function getTeaserTypeName(Component: TeaserComponentType): TeaserTypes {
  const result = Object.entries(typeToTeaser).find(
    ([t, component]) => component === Component
  );
  if (!result) {
    throw new Error("Couldn't find teaserTypeName for found Component");
  }
  return result[0] as TeaserTypes;
}

export function Teaser({ article }: { article: Article }) {
  const { articles } = useContext(ArticlesFeedContext);
  const { teaserType } = useContext(TeaserContext);
  const { teaserImpression } = useContext(TrackingContext);
  const { group } = useContext(TeaserGroupContext);
  const shouldBeHidden = useHidePremiumContent(article);

  const { handleRef } = useEventOnView(
    () => {
      if ((article.is_premium && !isThemeBox(group)) || isDynamicBox(group)) {
        teaserImpression(
          article,
          getPosition(article, articles, isDynamicBox(group)),
          getDynamicBoxName(group)
        );
      }
    },
    {
      threshold: 2 / 3
    }
  );
  const Component = teaserType
    ? typeToTeaser[teaserType]
    : getTeaserComponent({ article });

  const teaserTypeName = teaserType || getTeaserTypeName(Component);
  const cssClasses = [
    "teaser",
    `teaser--${teaserTypeName}`,
    article.main_resource ? "" : `teaser--${teaserTypeName}--no-main-resource`
  ];

  if (shouldBeHidden) {
    return null;
  }

  return (
    <TeaserContext.Provider
      value={{ teaserType: teaserTypeName as TeaserTypes }}
    >
      <div className={cssClasses.join(" ")} ref={handleRef}>
        <ArticleContainer article={article}>
          <Component article={article} />
        </ArticleContainer>
      </div>
    </TeaserContext.Provider>
  );
}
