import React, { useContext } from "react";

import { Ad } from "../../Ad";
import { ArticleContext } from "../../../contexts/ArticleContext";

export function ArticleAd() {
  const { article } = useContext(ArticleContext);
  if (article.sponsor) {
    return null;
  }
  return (
    <aside className="box hidden-xs hidden-sm">
      <Ad
        className="banner banner--articleside hidden-xs hidden-sm"
        placement="articleSide"
        id={`articleSide-${article.article_id}`}
      />
    </aside>
  );
}
