import React from "react";

import { ArticleLarge } from "./ArticleLarge";
import { ArticleInFeed } from "./ArticleInFeed";

export function ArticleComponent({
  type = "large",
  isNotExpandable,
  isMainArticle = false
}: {
  type: "large" | "feed";
  isNotExpandable?: boolean;
  isMainArticle?: boolean;
}) {
  if (type === "large") {
    return <ArticleLarge isMainArticle={isMainArticle} />;
  }
  if (type === "feed") {
    return <ArticleInFeed isNotExpandable={isNotExpandable} />;
  }
  return null;
}
