import React, { useContext } from "react";

import type { ArticleFormat, Resource, Text } from "../../../client";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { dmt, fromNow } from "../Resources/helpers/date";
import { resourceToComponentMap } from "../Resources/resourceToComponents";
import { isStoryVignette } from "../../../containers/SideBarContainer/SideBarSubscriptionsContainer";
import { getConfig } from "../../../config";

const nonCompactFormats: ArticleFormat[] = [
  "fact",
  "qna",
  "timeline",
  "licensed"
];

export function ArticleAgoOrBadge() {
  const { article } = useContext(ArticleContext);
  const { translation } = getConfig();

  if (article.is_premium && translation.premium_badge) {
    return (
      <div className="resource resource--article-badge premium-badge">
        {translation.premium_badge}
      </div>
    );
  } else if (nonCompactFormats.includes(article.format)) {
    return null;
  }

  return (
    <div
      className="resource resource--article-ago article-ago article-ago--article"
      title={dmt(article.changes.updated || article.changes.published)}
    >
      {fromNow(article.changes.updated || article.changes.published)}
    </div>
  );
}

function removeMarkups(mainText: Text) {
  delete mainText.paragraphs[0]?.text?.markup;
  return mainText;
}

export function MainText({ mainText }: { mainText: Text | null }) {
  if (mainText && !mainText.hide_in_teaser) {
    const ResourceComponent = resourceToComponentMap[mainText?.type];
    return <ResourceComponent resource={removeMarkups(mainText)} />;
  }

  return null;
}

export function MainResource({
  mainResource
}: {
  mainResource: Resource | null;
}) {
  if (mainResource) {
    const ResourceComponent = resourceToComponentMap[mainResource?.type];
    return <ResourceComponent resource={mainResource} />;
  }

  return null;
}

export function StoryVignette() {
  const { article } = useContext(ArticleContext);
  const resources = article.resources.filter(isStoryVignette);
  return (
    <>
      {resources.map((resource, index) => {
        const ResourceComponent = resourceToComponentMap[resource.type];
        return <ResourceComponent key={index} resource={resource} />;
      })}
    </>
  );
}
