import React, { useContext } from "react";

import type { Byline } from "../../../client";

import { isByline } from "../../../client";
import { ArticleContext } from "../../../contexts/ArticleContext";
import { BylineResource } from "../Resources/BylineResource";

export function ArticleMeta() {
  const { article } = useContext(ArticleContext);
  const byline = article.resources.find(resource => isByline(resource)) as
    | Byline
    | undefined;

  if (article.sponsor || !byline) {
    return null;
  }

  return (
    <section className="box">
      <BylineResource article={article} resource={byline} />
    </section>
  );
}
