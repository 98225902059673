import React, { useContext } from "react";

import { ArticleContext } from "../../../contexts/ArticleContext";
import {
  MainText,
  MainResource,
  StoryVignette,
  ArticleAgoOrBadge
} from "./helpers";

export default function SubscriptionTeaser() {
  const { article } = useContext(ArticleContext);

  return (
    <>
      <div className="teaser--subscription__story-vignette">
        <StoryVignette />
      </div>
      <div className="teaser--subscription__text">
        <h2>{article?.title.value}</h2>
        <MainText mainText={article?.main_text || null} />
        <ArticleAgoOrBadge />
      </div>
      <div className="teaser--subscription__image">
        <MainResource mainResource={article?.main_resource || null} />
      </div>
    </>
  );
}
