import React, { useContext } from "react";

import type { Title } from "../../../client";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { ArticleAgoOrBadge, MainResource, MainText } from "./helpers";
import { checkIfPassion } from "../../../helpers";
import { TitleResource } from "../Resources/TitleResource";
import { StoryVignetteResource } from "../Resources/StoryVignetteResource";

export default function LargeTeaser() {
  const { article } = useContext(ArticleContext);
  const title: Title = {
    type: "Title",
    text: article.title
  };

  return (
    <>
      {article?.story_vignette && (
        <StoryVignetteResource
          article={article}
          resource={article?.story_vignette}
        />
      )}
      <MainResource mainResource={article?.main_resource || null} />
      <TitleResource article={article} resource={title} />
      <MainText mainText={article?.main_text || null} />
      {!checkIfPassion(article?.teaser_layout) ? <ArticleAgoOrBadge /> : null}
    </>
  );
}
