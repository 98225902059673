import React, { useContext } from "react";

import { ArticleContext } from "../../../contexts/ArticleContext";

export default function FlashTeaser() {
  const { article } = useContext(ArticleContext);
  return (
    <>
      <h2 className={`resource resource-flash--title`}>
        <i className={`icon icon--ball icon--ball--flashball`} />
        <span>{article.story_vignette.title}</span> {article.title.value}
      </h2>
    </>
  );
}
