import React, { useContext } from "react";

import type { ReactNode } from "react";
import type { Article } from "../../../client/ContentApi/models";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { LinkToArticle, urlToArticle } from "../../Link";
import { TrackingContext } from "../../../contexts/TrackingContext";
import { AddBookmarkLink, RemoveBookmarkLink } from "../../Link/bookmarks";
import BookmarkSVG from "../../../assets/svg/bookmark.svg";
import { FeatureEnum, hasFeature } from "../../../config/features";
import { getConfig } from "../../../config";
import { useGlobalStore } from "../../../containers/GlobalStateContainer/store";

function ShareLink({
  children,
  shareItemClassName,
  url,
  shareTrackingTarget,
  inPopup = true
}: {
  children: ReactNode;
  shareItemClassName: string;
  url: string;
  inPopup?: boolean;
  shareTrackingTarget: "facebook" | "twitter" | "email" | "linkedin";
}) {
  const { shareButton } = useContext(TrackingContext);
  const { article } = useContext(ArticleContext);
  return (
    <a
      data-isclickable="true"
      href={url}
      className={`a sharebox-item sharebox-item--${shareItemClassName}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => {
        shareButton(shareTrackingTarget, article);

        if (!inPopup) {
          return;
        }
        e.preventDefault();
        window.open(url, "OMNI_SHARE", "width=400,height=400");
      }}
    >
      {children}
    </a>
  );
}

export function ShareArticle() {
  const { article } = useContext(ArticleContext);
  const { NEXT_PUBLIC_DOMAIN, translation } = getConfig();
  const {
    userInfo: { email },
    personalizedContent: { bookmarks = [] } = {}
  } = useGlobalStore();

  const getBookmarkLink = (article: Article): ReactNode => {
    const isBookmarked =
      Array.isArray(bookmarks) &&
      bookmarks.filter(bookmark => bookmark.article_id === article.article_id)
        .length > 0;

    if (isBookmarked) {
      return (
        <RemoveBookmarkLink article={article} className="sharebox-permalink a">
          <div className="bookmark">
            <div className="bookmark-icon-active">
              <BookmarkSVG />
            </div>
            Ta bort sparad artikel
          </div>
        </RemoveBookmarkLink>
      );
    }

    return (
      <AddBookmarkLink article={article} className="sharebox-permalink a">
        <div className="bookmark">
          <div className="bookmark-icon">
            <BookmarkSVG />
          </div>
          Spara artikel
        </div>
      </AddBookmarkLink>
    );
  };

  const encodedUrl = encodeURIComponent(
    `https://${NEXT_PUBLIC_DOMAIN}${urlToArticle(article)}`
  );
  const encodedTitle = encodeURIComponent(article.title.value);
  const encodedEmailOrNothing = email ? encodeURIComponent(email) : "";

  return (
    <section className="box clearfix">
      <h2 className="box-heading hidden-xs hidden-sm">Dela</h2>
      <div className="sharebox clearfix">
        <ShareLink
          shareItemClassName="fb"
          url={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
          shareTrackingTarget="facebook"
        >
          <i
            data-isclickable="true"
            className="a sharebox-item-icon sharebox-item-icon--fb icon icon--fb"
          />
          <span data-isclickable="true" className="sharebox-item-text">
            Facebook
          </span>
        </ShareLink>
        <ShareLink
          shareItemClassName="tw"
          shareTrackingTarget="twitter"
          url={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}&via=${
            translation.twitter.via
          }&original_referer=${encodeURIComponent(NEXT_PUBLIC_DOMAIN)}`}
        >
          <i
            data-isclickable="true"
            className="a sharebox-item-icon sharebox-item-icon--tw icon icon--tw"
          />
          <span data-isclickable="true" className="sharebox-item-text">
            Twitter
          </span>
        </ShareLink>

        <ShareLink
          shareTrackingTarget="linkedin"
          url={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedTitle}&source=LinkedIn`}
          shareItemClassName="linkedin"
          inPopup={false}
        >
          <i
            data-isclickable="true"
            className="a sharebox-item-icon sharebox-item-icon--linkedin icon icon--linkedin"
          />
          <span data-isclickable="true" className="sharebox-item-text">
            LinkedIn
          </span>
        </ShareLink>

        <ShareLink
          shareTrackingTarget="email"
          url={`mailto:${encodedEmailOrNothing}?subject=${encodedTitle}&body=${encodedTitle}%0A${encodedUrl}`}
          shareItemClassName="email"
          inPopup={false}
        >
          <i
            data-isclickable="true"
            className="a sharebox-item-icon sharebox-item-icon--email icon icon--email"
          />
          <span data-isclickable="true" className="sharebox-item-text">
            E-post
          </span>
        </ShareLink>
      </div>
      {hasFeature(FeatureEnum.BOOKMARKS) && getBookmarkLink(article)}
      <LinkToArticle
        article={article}
        className="sharebox-permalink a"
        target="_blank"
      >
        <i
          data-isclickable="true"
          className="sharebox-permalink-icon icon icon--external"
        />{" "}
        Öppna i ny flik
      </LinkToArticle>
    </section>
  );
}
