import React, { useContext } from "react";

import type { Article } from "../../../client";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { checkIfPassion } from "../../../helpers";
import * as date from "../Resources/helpers/date";
import { ArticleAgoOrBadge, MainResource } from "./helpers";

const ago = (article: Article) => {
  const publishedDate = article.changes && article.changes.published;
  const titleDate = publishedDate ? date.dmt(publishedDate) : "";

  return (
    <div className="article-tinyago" title={article.sponsor ? "" : titleDate}>
      {article.sponsor ? (
        "Annons"
      ) : !checkIfPassion(article?.teaser_layout) && article.changes ? (
        <ArticleAgoOrBadge />
      ) : null}
    </div>
  );
};

const Prefix = ({ article }: { article: Article }) => {
  if (!article.story_vignette || article.meta?.is_sponsored) return null;
  return (
    <span className="resource--vignette-prefix">
      {article.story_vignette.suffix}
      {article.story_vignette.suffix_separator}
    </span>
  );
};

export default function TinyTeaser() {
  const { article } = useContext(ArticleContext);

  return (
    <>
      <MainResource mainResource={article?.main_resource || null} />
      <h2>
        <Prefix article={article} />
        {article?.title.value}
      </h2>
      {ago(article)}
    </>
  );
}
